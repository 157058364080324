import * as React from 'react';
import Moment from 'react-moment';
import moment from 'moment'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Title from './Title';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import DashboardCard from '../components/card/dashboardCard';
import PaperDefault from '../components/paper/paperDefault';
import { Box } from '@mui/system';
import { getDashboards } from '../api/AmtApi';
import MUIDataTable from 'mui-datatables';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const mdTheme = createTheme();


function createData(time: string, amount?: number) {
  return { time, amount };
}



function DashboardContent() {
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [dashboard, setDashboard] = React.useState<any>("");
  const [latestSale, setLatestSale] = React.useState<any>("");
  const [latestDate, setLatestDate] = React.useState<any>("");
  const [todaySale, setTodaySale] = React.useState<any>("");
  const [todayCount, setTodayCount] = React.useState<any>("");
  const [chartData, setChartData] = React.useState<any>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<any>(false);
  
  const options = {
    selectableRowsHideCheckboxes: true,
    enableNestedDataAccess: ".",
    SelectableRows: false,
    selectableRowsHeader: false,
    fixedSelectColumn: false,
    elevation: 6,
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filterType: "dropdown",
  };
  const [dashboardList, setDashbardList] = React.useState<any>([]);
  const columns = [
    {
      name: "timestamp",
      label: "Timestamp",
      options: {
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => (
          <Moment format="YYYY-MM-DD HH:mm:ss" unix>{value}</Moment>
        ),
      }
    },
    {
      name: "buy.token",
      label: "Buy Token",
      options: {
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => (
          value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
        ),
      }
    },
    {
      name: "buy.fiat",
      label: "Buy Fiat",
      options: {
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => (
          value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
        ),
      }
    },
    {
      name: "sell.token",
      label: "Sell Token",
      options: {
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => (
          value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
        ),
      }
    },
    {
      name: "sell.fiat",
      label: "Sell Fiat",
      options: {
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => (
          value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
        ),
      }
    },
    {
      name: "summary",
      label: "Summary",
      options: {
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => (
          value.toLocaleString(undefined, {minimumFractionDigits: 2})
        ),
      }
    },
  ];
  const getDashboardData = async () => {
    try {
      const dashboard = await getDashboards();
      setDashbardList(dashboard.data.data);
      const { data } = dashboard.data;
      const dataChart: any[] | undefined = [];

      for (let i = 0; i < data.length; i++) {
        //let dateRange = moment.unix(Number(data[i].timestamp)).format("YYYY-MM-DD");
        let dateRange = new Date(Number(data[i].timestamp) * 1000).toLocaleDateString();
        dataChart.push(createData(dateRange.toString(), data[i].buy.token));
      }
      setChartData(dataChart);
      
      setLoading(false)
    } catch (error: any) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    setLoading(true)
    getDashboardData()
  }, []);
  
  return (
    <>
      {!loading &&
        <React.Fragment>
          
          <Container style={{ maxWidth: `2000px` }} sx={{ mt: 4, mb: 4, }}>
          <Grid container spacing={3}>
            {/* Chart */}

            {/*<Grid item xs={12} md={4} lg={4}>
              <DashboardCard total={0} count={0} title={"ยอดฝากวันนี้"} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DashboardCard total={0} count={0} date={0} mode="DATE" title={"ยอดถอนวันนี้"} />
            </Grid>
            {/* Recent Deposits */}
            {/*<Grid item xs={12} md={4} lg={4}>
              <DashboardCard total={0} count={0} title={"รวมทั้งหมด"} />
            </Grid>*/}
            {/* Recent Orders */}
            <Grid item xs={12}>
              <PaperDefault
                h={520}
              >
                <>
                  <Box >
                    <Title>ยอดซื้อ Token</Title>
                  </Box>
                  <ResponsiveContainer>
                    <LineChart
                      data={chartData}
                      margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                      }}
                    >
                      <XAxis
                        dataKey="time"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                      />
                      <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                      >
                        <Label
                          angle={270}
                          position="left"
                          style={{
                            textAnchor: 'middle',
                            fill: theme.palette.text.primary,
                            ...theme.typography.body1,
                          }}
                        >
                          Sales (THB)
                        </Label>
                      </YAxis>
                      <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="amount"
                        stroke={theme.palette.primary.main}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </>
              </PaperDefault>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4, }}>
            <MUIDataTable
              xs={{ bgColor: "red" }}
              title={"Dashboard"}
              data={dashboardList}
              columns={columns}
              // @ts-ignore
              options={options}
            />
          </Container>
        </React.Fragment>
      }
    </>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}

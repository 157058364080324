import * as React from 'react';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import { createUsers, editUsers, getBankInformation, getListUsers } from '../api/AmtApi';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { snackBarAlertState } from '../recoil/atom';
import { useSetRecoilState } from 'recoil';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Users() {
  const [responsive, setResponsive] = useState<String>("");
  const [userList, setUserList] = React.useState<any>("");
  const [action, setAction] = React.useState<any>("create");
  const navigate = useNavigate();
  const columns = [
    "name",
    "wallet_address",
    "bank_code",
    "bank_account",
    { 
      name: "",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
          return (
            <Button onClick={() => {
              console.log(tableMeta);
              setAction("edit");
              setName(tableMeta.rowData[0]);
              setWalletAddress(tableMeta.rowData[1]);
              setBankAccount(tableMeta.rowData[3]);
              setBankCode(tableMeta.rowData[2]);
              handleClickOpen();
              }}>
              Edit
            </Button>
          );
        }
      }
    }
  ];
  const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);
  const [loading, setLoading] = React.useState<any>(true);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState<string>("");
  const [walletAddress, setWalletAddress] = React.useState<string>("");
  const [bankAccount, setBankAccount] = React.useState<string>("");
  const [bankCode, setBankCode] = React.useState<string>("");
  const [bankCodeList, setBankCodeList] = React.useState<any>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetForm = () => {
    setWalletAddress("")
    setName("")
    setBankAccount("")
    setBankCode("")
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const dataSubmit = {
      name: name,
      wallet_address: walletAddress,
      bank_code: bankCode,
      bank_account: bankAccount,
    };
    try {
      const response = action == 'create' ? await createUsers(dataSubmit) : await editUsers(dataSubmit, walletAddress);
      if (response.data) {
        setOpen(false);
        getUser();
      } else {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }catch (error:any) {
      setSnackBarAlertState({
        open:true,
        severity:'error',
        message:'Something wrong'
      })
    }
  };
  const options = {
    selectableRowsHideCheckboxes: true,
    enableNestedDataAccess: ".",
    SelectableRows: false,
    selectableRowsHeader: false,
    fixedSelectColumn: false,
    elevation: 6,
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filterType: "dropdown",
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={()=>{resetForm(); setAction("create"); handleClickOpen();}}>
            <AddIcon/>
          </IconButton>

          <Dialog open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>{action=='create' ? 'Add' : 'Edit'} User</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="walletAddress"
                    label="Wallet Address"
                    value={walletAddress}
                    onChange={(e) => {
                      setWalletAddress(e.target.value);
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Bank Code</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bankCode}
                      label="Bank Code"
                      onChange={(e) => {
                        setBankCode(e.target.value);
                      }}
                    >
                      {bankCodeList.map((bankCode:any) => (
                        <MenuItem key={bankCode.bank_code} value={bankCode.bank_code}>{bankCode.bank_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="bankAccount"
                    label="Bank Account"
                    value={bankAccount}
                    onChange={(e) => {
                      setBankAccount(e.target.value);
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">{action=='create' ? 'Add' : 'Edit'} User</Button>
                </DialogActions>
                </Box>
              </Dialog>
        </>
      );
    },
  };
  const getUser = async () => {
    try {
      const userlist = await getListUsers();
      setUserList(userlist.data.data.records);
      setLoading(false)
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('access_token');
        navigate('/', { replace: true })
      }
    }
  }

  const getBankCode = async () => {
    try {
      const banklist = await getBankInformation();
      setBankCodeList(banklist.data.data);
      //console.log(banklist);
      //setUserList(userlist.data.data.records);
      //setLoading(false)
    } catch (error: any) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    setLoading(true)
    getUser()
    getBankCode()
  }, []);

  return (
    <>
      {!loading &&
        <React.Fragment>
          <Container maxWidth={false} sx={{ mt: 4, mb: 4, }}>
            <MUIDataTable
              xs={{ bgColor: "red" }}
              title={"User List"}
              data={userList}
              columns={columns}
              // @ts-ignore
              options={options}
            />
          </Container>
        </React.Fragment>
      }
    </>
  );
}

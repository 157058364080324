import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link } from "react-router-dom";

// export const mainListItems = (
//   <React.Fragment>
//     <ListItemButton component={Link} to="/">
//       <ListItemIcon>
//         <DashboardIcon />
//       </ListItemIcon>
//       <ListItemText primary="Dashboard" />
//     </ListItemButton>
//     <ListItemButton component={Link} to="/orders">
//       <ListItemIcon>
//         <ShoppingCartIcon />
//       </ListItemIcon>
//       <ListItemText primary="Orders" />
//     </ListItemButton>
//     {/*<ListItemButton component={Link} to="/products">
//       <ListItemIcon>
//         <PeopleIcon />
//       </ListItemIcon>
//       <ListItemText primary="Products" />
//     </ListItemButton>*/}
//   </React.Fragment>
// );


type Props = {
  onCloseMenu: () => void;
}

const MainListItems = ({ onCloseMenu }: Props) => {
  return (
    <React.Fragment>
      <ListItemButton component={Link} to="/" onClick={onCloseMenu}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" onClick={onCloseMenu} />
      </ListItemButton>
      <ListItemButton component={Link} to="/users" onClick={onCloseMenu}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
      <ListItemButton component={Link} to="/sellers" onClick={onCloseMenu}>
        <ListItemIcon>
          <AssuredWorkloadIcon />
        </ListItemIcon>
        <ListItemText primary="Sellers" />
      </ListItemButton>
      <ListItemButton component={Link} to="/merchants" onClick={onCloseMenu}>
        <ListItemIcon>
          <AttachMoneyIcon />
        </ListItemIcon>
        <ListItemText primary="Shops" />
      </ListItemButton>
      <ListItemButton component={Link} to="/orders" onClick={onCloseMenu}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>
    </React.Fragment>
  )
}

export default MainListItems

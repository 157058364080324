import { Paper } from '@mui/material';
import React from 'react'

type Props = {
  children: JSX.Element;
  h?: number;
}

const PaperDefault = ({ children, h }: Props) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        borderRadius: '10px',
        height: h ? h : "auto",
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
      }}
    >
      {children}
    </Paper>
  )
}

export default PaperDefault
import { InternalAxiosRequestConfig } from "axios";
import { storage } from "../util/common";
import { SESSION } from "../util/constants";

export const appendCognitoToken = (request: InternalAxiosRequestConfig) => {
  request.headers = request.headers ?? {};
  //const token = storage(SESSION.TOKEN);
  const token = localStorage.getItem("jwtToken");
  request.headers["Authorization"] = `Bearer ${token}`;
  return request;
};

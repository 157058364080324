import { Container } from "@mui/material";
import MUIDataTable from "mui-datatables";
import * as React from "react";
import { useState } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  createUsers,
  editUsers,
  getBankInformation,
  getListOders,
} from "../api/AmtApi";
import { snackBarAlertState } from "../recoil/atom";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Orders() {
  const [responsive, setResponsive] = useState<String>("");
  const [userList, setUserList] = React.useState<any>("");
  const [action, setAction] = React.useState<any>("create");
  const navigate = useNavigate();
  const columns = [
    {
      name: "created_at",
      label: "Created at",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Moment format="YYYY-MM-DD HH:mm:ss" unix>
            {value}
          </Moment>
        ),
      },
    },
    {
      name: "expired_at",
      label: "Expired at",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Moment format="YYYY-MM-DD HH:mm:ss" unix>
            {value}
          </Moment>
        ),
      },
    },
    {
      name: "order_id",
      label: "Order Id",
    },
    {
      name: "side",
      label: "Side",
    },
    {
      name: "deposit_bank",
      label: "Deposit Bank",
    },
    {
      name: "payment_info.pay.name",
      label: "Pay name",
    },
    {
      name: "pay_amount",
      label: "Pay Amount",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      },
    },
    {
      name: "pay_currency",
      label: "Pay Currency",
    },
    {
      name: "pay_address",
      label: "Pay Address",
    },
    {
      name: "received_amount",
      label: "Received Amount",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
      },
    },
    {
      name: "received_partner",
      label: "Received Partner",
    },
    {
      name: "received_currency",
      label: "Received Currency",
    },
    {
      name: "received_address",
      label: "Received Address",
    },
    {
      name: "txn_url",
      label: "TxHash",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <a href={value} target="_blank">
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "shop.shop_name",
      label: "Shop Name",
    },

    /*"shop.side",
    "shop.line",
    "shop.min_amount",
    "shop.available",
    "shop.receive_currency",
    "shop.shop_name",
    "shop.seller_address",
    "shop.pay_currency",
    "shop.promptpay",
    "shop.start_time",
    "shop.shop_id",
    "shop.rate",
    "shop.total_amount",
    "shop.percentage",
    "shop.qr_payment",
    "shop.max_amount",
    "shop.rate_display",
    "shop.status",
    "received_address",
    "txn_url",
    "received_partner_id",
    "received_partner",
    "pay_currency",
    "shop_id",
    "txn_hash",
    "payment_info.pay.bank_code",
    "payment_info.pay.name",
    /*{ 
      name: "",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
          return (
            <Button onClick={() => {
              setAction("edit");
              setName(tableMeta.rowData[0]);
              setWalletAddress(tableMeta.rowData[1]);
              setBankAccount(tableMeta.rowData[3]);
              setBankCode(tableMeta.rowData[2]);
              handleClickOpen();
              }}>
              Edit
            </Button>
          );
        }
      }
    }*/
  ];
  const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);
  const [loading, setLoading] = React.useState<any>(true);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState<string>("");
  const [walletAddress, setWalletAddress] = React.useState<string>("");
  const [bankAccount, setBankAccount] = React.useState<string>("");
  const [bankCode, setBankCode] = React.useState<string>("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetForm = () => {
    setWalletAddress("");
    setName("");
    setBankAccount("");
    setBankCode("");
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const dataSubmit = {
      name: name,
      wallet_address: walletAddress,
      bank_code: bankCode,
      bank_account: bankAccount,
    };
    try {
      const response =
        action == "create"
          ? await createUsers(dataSubmit)
          : await editUsers(dataSubmit, walletAddress);
      if (response.data) {
        setOpen(false);
        getUser();
      } else {
        setSnackBarAlertState({
          open: true,
          severity: "error",
          message: "Something wrong",
        });
      }
    } catch (error: any) {
      setSnackBarAlertState({
        open: true,
        severity: "error",
        message: "Something wrong",
      });
    }
  };
  const options = {
    selectableRowsHideCheckboxes: true,
    enableNestedDataAccess: ".",
    SelectableRows: false,
    selectableRowsHeader: false,
    fixedSelectColumn: false,
    elevation: 6,
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filterType: "dropdown",
    /*customToolbar: () => {
      return (
        <>
          <IconButton onClick={()=>{resetForm(); setAction("create"); handleClickOpen();}}>
            <AddIcon/>
          </IconButton>

          <Dialog open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>{action=='create' ? 'Add' : 'Edit'} Order</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="walletAddress"
                    label="Wallet Address"
                    onChange={(e) => {
                      setWalletAddress(e.target.value);
                    }}
                  />
                  <TextField
                    margin="dense"
                    id="bankCode"
                    label="Bank Code"
                    type="string"
                    fullWidth
                    variant="standard"
                    value={bankCode}
                    onChange={(e) => {
                      setBankCode(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="bankAccount"
                    label="Bank Account"
                    onChange={(e) => {
                      setBankAccount(e.target.value);
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">{action=='create' ? 'Add' : 'Edit'} User</Button>
                </DialogActions>
                </Box>
              </Dialog>
        </>
      );
    },*/
  };
  const getUser = async () => {
    try {
      const userlist = await getListOders();
      console.log(userlist.data.data);
      setUserList(userlist.data.data.records);
      setLoading(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("access_token");
        navigate("/", { replace: true });
      }
    }
  };

  const getBankCode = async () => {
    try {
      const banklist = await getBankInformation();
      console.log(banklist);
      //setUserList(userlist.data.data.records);
      //setLoading(false)
    } catch (error: any) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    getUser();
    getBankCode();
  }, []);

  return (
    <>
      {!loading && (
        <React.Fragment>
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <MUIDataTable
              xs={{ bgColor: "red" }}
              title={"Order List"}
              data={userList}
              columns={columns}
              // @ts-ignore
              options={options}
            />
          </Container>
        </React.Fragment>
      )}
    </>
  );
}

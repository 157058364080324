import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  palette: {
    secondary: {
      light: '#E9E6E6',
      main: '#685C6A',
      dark: '#F4F4F4',
      contrastText: '#F4F4F4',
    },
  },
});

export default customTheme;

import * as React from 'react';
import Moment from 'react-moment';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import { createMerchants, createUsers, editMerchants, editUsers, getBankInformation, getListMerchant, getListMerchants, getListSellers, getListUsers } from '../api/AmtApi';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { snackBarAlertState } from '../recoil/atom';
import { useSetRecoilState } from 'recoil';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Sellers() {
  const [responsive, setResponsive] = useState<String>("");
  const [userList, setUserList] = React.useState<any>("");
  const [action, setAction] = React.useState<any>("create");
  const navigate = useNavigate();
  const columns = [
    "shop_id",
    "shop_name",
    "seller_address",
    "side",
    "available",
    "max_amount",
    "min_amount",
    "rate",
    "rate_display",
    "pay_currency",
    "received_currency",
    {
      name: "start_time",
      label: "Start Time",
      options: {
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => (
          <Moment format="YYYY-MM-DD HH:mm:ss" unix>{value}</Moment>
        ),
      }
    },
    "status",
    { 
      name: "",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
          return (
            <Button onClick={() => {
              console.log(tableMeta.rowData);
              setAction("edit");
              setShopName(tableMeta.rowData[1]);
              setSellerAddress(tableMeta.rowData[2]);
              setSide(tableMeta.rowData[3]);
              setAvailable(tableMeta.rowData[4]);
              setMaxAmount(tableMeta.rowData[5]);
              setMinAmount(tableMeta.rowData[6]);
              setRate(tableMeta.rowData[7]);
              setRateDisplay(tableMeta.rowData[8]);
              setPayCurrency(tableMeta.rowData[9]);
              setReceivedCurrency(tableMeta.rowData[10]);
              setStatus(tableMeta.rowData[12]);
              handleClickOpen();
              }}>
              Edit
            </Button>
          );
        }
      }
    }
  ];
  const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);
  const [loading, setLoading] = React.useState<any>(true);
  const [open, setOpen] = React.useState(false);
  const [shopId, setShopId] = React.useState<string>("");
  const [shopName, setShopName] = React.useState<string>("");
  const [side, setSide] = React.useState<string>("");
  const [available, setAvailable] = React.useState<string>("");
  const [maxAmount, setMaxAmount] = React.useState<string>("");
  const [minAmount, setMinAmount] = React.useState<string>("");
  const [payCurrency, setPayCurrency] = React.useState<string>("");
  const [percentage, setPercentage] = React.useState<string>("");
  const [rate, setRate] = React.useState<string>("");
  const [rateDisplay, setRateDisplay] = React.useState<string>("");
  const [receivedCurrency, setReceivedCurrency] = React.useState<string>("");
  const [sellerAddress, setSellerAddress] = React.useState<string>("");
  const [line, setLine] = React.useState<string>("");
  const [status, setStatus] = React.useState<string>("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetForm = () => {
    setShopName("")
    setSide("")
    setAvailable("")
    setMaxAmount("")
    setMinAmount("")
    setPayCurrency("")
    setPercentage("")
    setRate("")
    setRateDisplay("")
    setSellerAddress("")
    setStatus("")
    setReceivedCurrency("")
    setLine("")
    setShopId("")
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const dataSubmit = {
      shop_name: shopName,
      side: side,
      available: available,
      max_amount: maxAmount,
      min_amount: minAmount,
      pay_currency: payCurrency,
      percentage: percentage,
      rate: rate,
      rate_display: rateDisplay,
      received_currency: receivedCurrency,
      seller_address: sellerAddress,
      status: status,
      line: line
    };
    try {
      const response = action == 'create' ? await createMerchants(dataSubmit) : await editMerchants(dataSubmit, shopId);
      if (response.data) {
        setOpen(false);
        getUser();
      } else {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }catch (error:any) {
      setSnackBarAlertState({
        open:true,
        severity:'error',
        message:'Something wrong'
      })
    }
  };
  const options = {
    selectableRowsHideCheckboxes: true,
    enableNestedDataAccess: ".",
    SelectableRows: false,
    selectableRowsHeader: false,
    fixedSelectColumn: false,
    elevation: 6,
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filterType: "dropdown",
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={()=>{resetForm(); setAction("create"); handleClickOpen();}}>
            <AddIcon/>
          </IconButton>

          <Dialog open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>{action=='create' ? 'Add' : 'Edit'} Shop</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="shopName"
                    label="Shop Name"
                    name="shopName"
                    autoComplete="shopname"
                    autoFocus
                    value={shopName}
                    onChange={(e) => {
                      setShopName(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="sellerAddress"
                    label="Seller Address"
                    value={sellerAddress}
                    onChange={(e) => {
                      setSellerAddress(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="side"
                    label="Side"
                    value={side}
                    onChange={(e) => {
                      setSide(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="available"
                    type="number"
                    label="Available"
                    value={available}
                    onChange={(e) => {
                      setAvailable(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="maxAmount"
                    type="number"
                    label="Max Amount"
                    value={maxAmount}
                    onChange={(e) => {
                      setMaxAmount(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    id="minAmount"
                    label="Min Amount"
                    value={minAmount}
                    onChange={(e) => {
                      setMinAmount(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{
                      step: "0.01"
                    }}
                    id="rate"
                    label="Rate"
                    value={rate}
                    onChange={(e) => {
                      setRate(e.target.value);
                    }}
                  />
                  {/*<TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{
                      step: "0.01"
                    }}
                    id="percentage"
                    label="Percentage"
                    value={percentage}
                    onChange={(e) => {
                      setPercentage(e.target.value);
                    }}
                  />*/}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{
                      step: "0.01"
                    }}
                    id="rateDisplay"
                    value={rateDisplay}
                    label="Rate Display"
                    onChange={(e) => {
                      setRateDisplay(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="payCurrency"
                    label="Pay Currency"
                    value={payCurrency}
                    onChange={(e) => {
                      setPayCurrency(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="receivedCurrency"
                    label="Received Currency"
                    value={receivedCurrency}
                    onChange={(e) => {
                      setReceivedCurrency(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="status"
                    label="Status"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">{action=='create' ? 'Add' : 'Edit'} Shop</Button>
                </DialogActions>
                </Box>
              </Dialog>
        </>
      );
    },
  };
  const getUser = async () => {
    try {
      const userlist = await getListMerchants();
      setUserList(userlist.data.data.records);
      setLoading(false)
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('access_token');
        navigate('/', { replace: true })
      }
    }
  }

  React.useEffect(() => {
    setLoading(true)
    getUser()
  }, []);

  return (
    <>
      {!loading &&
        <React.Fragment>
          <Container maxWidth={false} sx={{ mt: 4, mb: 4, }}>
            <MUIDataTable
              xs={{ bgColor: "red" }}
              title={"Shop List"}
              data={userList}
              columns={columns}
              // @ts-ignore
              options={options}
            />
          </Container>
        </React.Fragment>
      }
    </>
  );
}

import * as React from 'react';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import { createSellers, createUsers, editSellers, editUsers, getBankInformation, getListSellers, getListUsers } from '../api/AmtApi';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { snackBarAlertState } from '../recoil/atom';
import { useSetRecoilState } from 'recoil';
import { createScanner } from 'typescript';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Sellers() {
  const [responsive, setResponsive] = useState<String>("");
  const [userList, setUserList] = React.useState<any>("");
  const [action, setAction] = React.useState<any>("create");
  const navigate = useNavigate();
  const columns = [
    {
      name: "wallet_address",
      label: "Wallet Address",
    },
    {
      name: "private_key",
      label: "Private Key",
    },
    {
      name: "line",
      label: "Line",
    },
    {
      name: "bank_code",
      label: "Bank Code",
    },
    {
      name: "bank_account",
      label: "Bank Account",
    },
    {
      name: "buy.min",
      label: "Buy min",
    },
    {
      name: "buy.rate",
      label: "Buy rate",
    },
    {
      name: "buy.max",
      label: "Buy max",
    },
    {
      name: "sell.min",
      label: "Sell min",
    },
    {
      name: "sell.rate",
      label: "Sell rate",
    },
    {
      name: "sell.max",
      label: "Sell max",
    },
    { 
      name: "",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
          return (
            <Button onClick={() => {
              console.log(tableMeta.rowData);
              setAction("edit");
              setWalletAddress(tableMeta.rowData[0]);
              setPrivateKey(tableMeta.rowData[1]);
              setLine(tableMeta.rowData[2]);
              setBankCode(tableMeta.rowData[3]);
              setBankAccount(tableMeta.rowData[4]);
              setBuyMin(tableMeta.rowData[5]);
              setBuyRate(tableMeta.rowData[6]);
              setBuyMax(tableMeta.rowData[7]);
              setSellMin(tableMeta.rowData[8]);
              setSellRate(tableMeta.rowData[9]);
              setSellMax(tableMeta.rowData[10]);
              handleClickOpen();
              }}>
              Edit
            </Button>
          );
        }
      }
    }
  ];
  const setSnackBarAlertState = useSetRecoilState(snackBarAlertState);
  const [loading, setLoading] = React.useState<any>(true);
  const [open, setOpen] = React.useState(false);
  const [walletAddress, setWalletAddress] = React.useState<string>("");
  const [bankAccount, setBankAccount] = React.useState<string>("");
  const [bankCode, setBankCode] = React.useState<string>("");
  const [privateKey, setPrivateKey] = React.useState<string>("");
  const [line, setLine] = React.useState<string>("");
  const [buyMin, setBuyMin] = React.useState<string>("");
  const [buyRate, setBuyRate] = React.useState<string>("");
  const [buyMax, setBuyMax] = React.useState<string>("");
  const [sellMin, setSellMin] = React.useState<string>("");
  const [sellRate, setSellRate] = React.useState<string>("");
  const [sellMax, setSellMax] = React.useState<string>("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const resetForm = () => {
    setWalletAddress("");
    setPrivateKey("");
    setLine("");
    setBankCode("");
    setBankAccount("");
    setBuyMin("");
    setBuyRate("");
    setBuyMax("");
    setSellMin("");
    setSellRate("");
    setSellMax("");
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const dataSubmit = {
      wallet_address: walletAddress,
      private_key: privateKey,
      line: line,
      bank_code: bankCode,
      bank_account: bankAccount,
      buy: {
        min: buyMin,
        max: buyMax,
        rate: buyRate,
      },
      sell: {
        min: sellMin,
        max: sellMax,
        rate: sellRate,
      },
    };
    try {
      const response = action == 'create' ? await createSellers(dataSubmit) : await editSellers(dataSubmit, walletAddress);
      if (response.data.status.code == "20000") {
        setOpen(false);
        getUser();
      } else {
        setSnackBarAlertState({
          open:true,
          severity:'error',
          message:'Something wrong'
        })
      }
    }catch (error:any) {
      setSnackBarAlertState({
        open:true,
        severity:'error',
        message:'Something wrong'
      })
    }
  };
  const options = {
    selectableRowsHideCheckboxes: true,
    enableNestedDataAccess: ".",
    SelectableRows: false,
    selectableRowsHeader: false,
    fixedSelectColumn: false,
    elevation: 6,
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filterType: "dropdown",
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={()=>{resetForm(); setAction("create"); handleClickOpen();}}>
            <AddIcon/>
          </IconButton>

          <Dialog open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <DialogTitle>{action=='create' ? 'Add' : 'Edit'} Seller</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="walletAddress"
                    label="Wallet Address"
                    value={walletAddress}
                    onChange={(e) => {
                      setWalletAddress(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="privateKey"
                    label="Private Key"
                    value={privateKey}
                    onChange={(e) => {
                      setPrivateKey(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="line"
                    label="Line"
                    value={line}
                    onChange={(e) => {
                      setLine(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="bankcode"
                    label="Bank Code"
                    value={bankCode}
                    onChange={(e) => {
                      setBankCode(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="bankAccount"
                    label="Bank Account"
                    value={bankAccount}
                    onChange={(e) => {
                      setBankAccount(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="buymin"
                    label="Buy Min"
                    value={buyMin}
                    onChange={(e) => {
                      setBuyMin(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="buyrate"
                    label="Buy Rate"
                    value={buyRate}
                    onChange={(e) => {
                      setBuyRate(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="buymax"
                    label="Buy Max"
                    value={buyMax}
                    onChange={(e) => {
                      setBuyMax(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="sellmin"
                    label="Sell Min"
                    value={sellMin}
                    onChange={(e) => {
                      setSellMin(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="sellrate"
                    label="Sell Rate"
                    value={sellRate}
                    onChange={(e) => {
                      setSellRate(e.target.value);
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="sellmax"
                    label="Sell Max"
                    value={sellMax}
                    onChange={(e) => {
                      setSellMax(e.target.value);
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">{action=='create' ? 'Add' : 'Edit'} User</Button>
                </DialogActions>
                </Box>
              </Dialog>
        </>
      );
    },
  };
  const getUser = async () => {
    try {
      const userlist = await getListSellers();
      setUserList(userlist.data.data.records);
      setLoading(false)
    } catch (error: any) {
      if (error.response.status === 401) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('access_token');
        navigate('/', { replace: true })
      }
    }
  }

  React.useEffect(() => {
    setLoading(true)
    getUser()
  }, []);

  return (
    <>
      {!loading &&
        <React.Fragment>
          <Container maxWidth={false} sx={{ mt: 4, mb: 4, }}>
            <MUIDataTable
              xs={{ bgColor: "red" }}
              title={"Seller List"}
              data={userList}
              columns={columns}
              // @ts-ignore
              options={options}
            />
          </Container>
        </React.Fragment>
      }
    </>
  );
}

import { atom } from "recoil";
import { SnackbarAlert } from "../typings/model";

export const snackBarAlertState = atom<SnackbarAlert>({
    key: "snackBarAlert",
    default: {
      open: false,
      severity: 'success',
      message: 'Success'
    },
  });
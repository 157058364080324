import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import MainListItems from './listItems';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import customTheme from '../theme';
import { AppContext } from '../context/appSate';
import { useContext } from 'react';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        AMT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth: number = 310;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const Layout = () => {
  // @ts-ignore
  // const { openSideMenu, setOpenSideMenu } = useState(false)
  const { openSideMenu, setOpenSideMenu } = useContext(AppContext)
  const toggleDrawer = () => {
    setOpenSideMenu(!openSideMenu);
  };
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('access_token');
    navigate('/', { replace: true })
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={openSideMenu}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(openSideMenu && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ width: "100%" }}>

              {!openSideMenu && (
                <Box
                  sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none', xl: 'none' } }}
                >
                  <img src="/amt-logo.png" width={'150px'} />
                </Box>
              )}

              <Box
                sx={{ flexGrow: 1, display: { xs: "none", sm: 'block', md: "block", lg: 'block', xl: 'block' } }}
              >
                <img src="/amt-logo.png" width={'150px'} />
              </Box>
            </Box>
            <IconButton color="inherit" onClick={() => logout()}>
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={openSideMenu}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: [1],
            }}
          >
            {openSideMenu && (
              <Box sx={{ textAlign: "left" }}>
                <Typography variant='h5'>AMT</Typography>
              </Box>
            )}
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems onCloseMenu={() => setOpenSideMenu(true)} />
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {/* desktop */}
          <Container style={{ maxWidth: `3000px` }} sx={{ mt: 4, mb: 4, display: { xs: 'none', sm: "block", md: "block", lg: 'block', xl: 'block' } }}>
            <Grid container spacing={3}>
              <Outlet />
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>

          {/* mobile */}
          <Container style={{ maxWidth: `3000px` }} sx={{ mt: 2, pl: 4, mb: 4, display: { xs: 'block', sm: 'none', md: 'none' } }}>
            {!openSideMenu && (
              <>
                <Grid container spacing={3}>
                  <Outlet />
                </Grid>
                <Copyright sx={{ pt: 4 }} />
              </>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Layout

import React from 'react';
import { Routes, Route, Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Dashboard from './pages/Dashboard';
import Layout from './layout/Layout';
import SignIn from './pages/Signin';
import Users from './pages/Users';
import SnackBarAlert from './components/SnackBarAlert';
import Sellers from './pages/Sellers';
import Merchants from './pages/Merchants';
import Orders from './pages/Orders';

interface AuthContextType {
  user: any;
}
let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);
  let value = { user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return JSON.parse(localStorage.getItem("userInfo") || "{}");
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isLogin) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <div className="App">
      <SnackBarAlert />
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route>
            <Route element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/sellers" element={<Sellers />} />
              <Route path="/merchants" element={<Merchants />} />
              <Route path="/orders" element={<Orders />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;

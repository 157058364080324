import React, { createContext, useState, useEffect } from 'react'

export const AppContext = createContext("")

type Props = {
  children: JSX.Element
}
const AppState = ({ children }: Props) => {
  const [openSideMenu, setOpenSideMenu] = useState(false)
  return (
    // @ts-ignore
    <AppContext.Provider value={{
      openSideMenu, setOpenSideMenu
    }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppState
import axios from "axios";
import config from "../config/api.json";
import {
  BankInformation,
  DashboardRecord,
  DataResult,
  MerchantRequest,
  MerchantResponse,
  OrderHistoryResponse,
  OrderRequest,
  OrderResponse,
  PutProfileRequest,
  SellerResponse,
  User,
  UserResponse,
} from "../typings/model.api";
import { appendCognitoToken } from "./interceptor";

export const amtAxios = axios.create({
  baseURL: config.api_host,
});

amtAxios.interceptors.request.use(appendCognitoToken);

export const getListMerchant = async (params: MerchantRequest) => {
  return amtAxios.get<DataResult<MerchantResponse>>("/merchants", {
    params: params,
  });
};

export const getListUsers = async () => {
  return amtAxios.get<DataResult<UserResponse>>("/users");
};

export const createUsers = async (dataSubmit:any) => {
  return amtAxios.post<DataResult<UserResponse>>("/users", dataSubmit);
};

export const editUsers = async (dataSubmit:any, walletAddress:string) => {
  return amtAxios.put<DataResult<UserResponse>>(`/users/${walletAddress}`, dataSubmit);
};

export const getBankInformation = async () => {
  return amtAxios.get<DataResult<BankInformation[]>>("/options/bank_code");
};

export const getListSellers = async () => {
  return amtAxios.get<DataResult<SellerResponse>>("/sellers");
};

export const createSellers = async (dataSubmit:any) => {
  return amtAxios.post<DataResult<SellerResponse>>("/sellers", dataSubmit);
};

export const editSellers = async (dataSubmit:any, walletAddress:string) => {
  return amtAxios.put<DataResult<SellerResponse>>(`/sellers/${walletAddress}`, dataSubmit);
};

export const getListMerchants = async () => {
  return amtAxios.get<DataResult<MerchantResponse>>("/shops");
};

export const createMerchants = async (dataSubmit:any) => {
  return amtAxios.post<DataResult<MerchantResponse>>("/shops", dataSubmit);
};

export const editMerchants = async (dataSubmit:any, id:string) => {
  return amtAxios.post<DataResult<MerchantResponse>>(`/shops/${id}`, dataSubmit);
};

export const getListOders = async () => {
  return amtAxios.get<DataResult<OrderResponse>>("/orders");
};

export const getDashboards = async () => {
  return amtAxios.get<DataResult<DashboardRecord[]>>("/dashboard/overview");
};

